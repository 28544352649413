.demo-container{width: 80%; margin: auto; display: flex; flex-direction: column; align-items: center; position: relative; top: 20%;
  .title-container{ width: 80%; background-color: #fff; margin: 16px; padding: 8px;}
    h2{ text-align: center; }
    p{ font-size: 16px;}
  .users-container{width: 80%; display: flex; justify-content: space-between; align-items: 'center';
    .user-item{ padding: 8px; cursor: pointer; background: white;
      img{width: 100%; height: auto; display: block;}
      h3{font-weight: bold; text-align: center; margin: 8px;}
      &:hover{border: 1px solid lightgray;}   
    }
  }
}

@media screen and (max-width: 639px){
  .demo-container{width: 80%; margin: auto; display: flex; flex-direction: column; align-items: center; position: relative; top: 20%;
    .title-container{ width: 80%; background-color: #fff; margin: 16px; padding: 8px;}
      h2{ text-align: center; }
      p{ font-size: 16px;}
    .users-container{width: 80%; display: inline-block;
      .user-item{ padding: 8px; cursor: pointer; background: white;
        img{width: 100%; height: auto; display: block;}
        h3{font-weight: bold; text-align: center; margin: 8px;}
        &:hover{border: 1px solid lightgray;}   
      }
    }
  }
}