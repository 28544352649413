@import "./variables.scss";

body{background-color: #333;}
@media screen and (max-width: 639px){
    body{background: #f0f2f4;}
    #_hj-f5b2a1eb-9b07_feedback{z-index: 10;}
    #_hj-f5b2a1eb-9b07_feedback[data-minimized-position="bottom_right"] ._hj-f5b2a1eb-9b07_hotjar_buddy{ bottom: 90vh; left: -90px;}
}
#root{background-color: #f0f2f4;}
#content{position: relative; width: 100%; max-width: 960px; min-height: 70vh; padding: 7em 2em 2em 2em; margin: 0 auto; z-index:1}
@media screen and (max-width: 639px){
    #content{padding-bottom: 60px;}
}
.clear{clear: both;}

ul{padding: 0; margin: 0; list-style: none;}

.centered-container{width: 100%; height: 100vh; display: flex; align-items: center; justify-content: center;
    > div{ text-align: center;}
}

.bold{font-weight: 600;}

/*CARDS*/
.card{width:100%; padding: 2em; background-color: white; border: 1px solid #e8e8e8;
    .title{ color: rgba(0, 0, 0, 0.85); font-weight: 500; font-size: 16px; }
    .content{}
}

/*GRID*/
.row{
    &.flex{ display: flex;}
    &.margin-0-5em > div{ margin: 0 0.5em; }
    &.margin-1em > div{ margin: 0 1em; }
    &.margin-2em > div{ margin: 0 1em; }
    > div{ flex-grow: 1; flex-basis: 0; }
}

@media screen and (max-width: 768px){
    .row{
        &.flex{ display: block; }
        > div{ width: 100%; }
    } 
}

/*BREADCRUMBS*/
.breadcrumb{margin: 0 0 1em 1em; font-size: 12px;
    span{ font-weight: 600;}
}

/*BUTTONS*/
.btn{display: inline-block; border-radius: 3px; background: none; cursor: pointer; padding: 3px 1em;}
.btn-validate{ background-color: $primaryBtnColor; border: none; color: white;
    &:hover{background-color: $secondaryBtnColor;}
}
