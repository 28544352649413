#teacher-gallery {
  .steps-container {
    margin: 2em 0 0 0;
  }

  .module-title {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 18px;
    border: solid 8px #e2e6e6;
    border-radius: 4px;
    h1 {
      margin: 0;
    }
    .btn-validate {
      display: block;
    }
  }

  // CARDS

  .ant-collapse {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .ant-collapse-item {
    border-bottom: none;
    .card {
      transform: scale(0.96);
      transition: all 0.08s ease-in-out;
      &:hover {
        transform: scale(1);
      }
      max-height: 300px;
      overflow: hidden;
      position: relative;
      border: solid 8px transparent;
      border-radius: 4px;
      display: flex;
      align-items: stretch;
      padding: 0;
      .shadow {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        box-shadow: inset 0 -40px 40px 0px white;
        width: 100%;
        height: 100%;
        background: transparent;
        border-radius: 4px;
      }
      .inner-card {
        background: #fefefe;
        height: 100%;
        width: 100%;
        display: flex;
        flex: 1;
        padding: 2em;
        flex-flow: column;
      }
    }
    .ant-steps {
      .ant-steps-item {
        .ant-steps-item-title {
          transition: all 0.06s ease-in-out;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          transform: scale(0.98);
          color: #000000B2;
        }
        .ant-steps-item-icon {
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }
        &:hover {
          .ant-steps-item-title {
            color: #000000;
            transform: scale(1);
          }
          .ant-steps-item-icon {
            box-shadow: inset 0 4px 12px rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
    &.ant-collapse-item-active .card {
      transform: scale(1);
      max-height: inherit;
      border-radius: 4px 4px 0 0;
      .shadow {
        visibility: hidden;
      }
    }
    .ant-collapse-header {
      padding: 0;
    }
  }
}
