.chat {
  margin: auto;
  overflow-y: auto;
  background-color: rgb(240, 242, 244);
  box-shadow: inset 0 -8px 20px rgba(0, 0, 0, 0.08);
  .scrollable {
    height: 80vh;
  }
}

.bot-avatar {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.button-action {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.button-action:hover {
  color: #40a9ff;
  border-color: #40a9ff;
}
