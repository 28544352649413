@import "./variables.scss";

#main-nav{ margin-left: auto;
    .mobile-menu-open, .mobile-menu-close{ display: none; cursor: pointer; }
    .mobile-menu-open{    
        span{ margin: 9px 1em; color: white; font-size: 32px;}
        .anticon-menu{ color: white; width: 20px; margin-right: 15px; }
    }
    .mobile-menu-close{
        span{color: #333;}
    }
    .menu-container{
        .nav-item{display: inline-block;
            a{ display: block; padding: 1.4em 1.5em; color: white; 
                .anticon-down,.anticon-up { padding-left: 10px; 
                    svg{ width: 10px; }
                }
                span{padding: 0 0 0 8px;}
                &.selected, &:hover{ background: #f0f2f4; color:#333}
            }
        }
    }
}

@media screen and (max-width: 1194px){
    #main-nav{
        .mobile-menu-open, .mobile-menu-close{display: block;}
        .menu-container{ position: fixed; right: 0; top: 0; z-index: 100; height: 100%; padding: 1em; background: white;box-shadow: 0 6px 8px rgba(0,0,0,0.2); transform: translate3d(300px,0,0); transition: all 0.2s ease-out;
            &.open{ -webkit-transform: translateZ(0); transform: translateZ(0); }
            .nav-item{ flex-grow: 1; flex-basis: 0; padding: 15px; display: block; max-width: 230px;
                a{ padding: 0.3em 0.8em; font-weight: bold; text-align: center; color: #333;
                    &.selected, &:hover{ background: white; color:#C04D29}
                    .anticon-down,.anticon-up {display: block; padding:0;}
                    span{padding: 0; display: block;}
                }
                .modules-list-side{ position: absolute; left:0; background-color: rgb(240,242,244); color:#333; z-index:1;
                    div { width: 100%; 
                        a { font-size: 12px; margin: 1em; font-weight: normal; background: white; line-height: 18px; text-align: left; padding: 10px 0 10px 5px;}
                    }
                }
            }
            .btn-logout{position: fixed; top: 0; right: 0;
                &:hover{ background: white; }
                a{padding: 0;
                    &:hover{ background: white; }
                    i{display: block; font-size: 18px;}
                    span{display: none;}
                }
            }
        }
    }
}





// @media screen and (max-width: 890px) {
//     #main-nav{
//         .mobile-menu-open, .mobile-menu-close{display: block;}
//         .menu-container{ position: fixed; right: 0; top: 0; z-index: 100; height: 100%; padding: 1em; background: white;box-shadow: 0 6px 8px rgba(0,0,0,0.2); transform: translate3d(300px,0,0); transition: all 0.2s ease-out;
//             &.open{ transform: translate3d(0,0,0);}

//             ul{
//                 &.primary{
//                     a{color: #333; padding: 1em;}
                
//                     li{
//                         &.nav-item{ display: block;
//                             > a{font-size: 16px;}
//                             > a.selected, > a:hover{}
//                             ul{ display: block; position: relative; left: auto; top: auto; border-radius: 0; box-shadow: none; padding: 0 0 0 1.5em;
//                                 li{
//                                     &:last-child a{}
//                                     a{
//                                         &.selected{}
//                                     }
//                                 }
//                             }
//                             &:hover{
//                                 a{}
//                                 ul{}
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }