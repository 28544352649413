.home-page {
  .module-title {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 18px;
    border: solid 8px #E2E6E6;
    border-radius: 4px;
    h1 {
      margin: 0;
    }
    .btn-validate {
      display: block;
    }
  }
  .logos {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0px 135px;
    img {
      width: auto;
      padding: 10px;
      height: 70px;
    }
  }
  .message-home {
    margin: 1em 0 2em 0;
    div {
      display: inline-block;
      padding: 1em;
      background: #fff;
      font-size: 20px;
      color: #333;
      border-radius: 16px 16px 16px 0px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }
  }
}

