.dashboard-container{
    &.student{}
    &.teacher{
        .classroom-container{margin: 2em 0;}
        ul{
            li{ width: 20%; display: inline-block; padding: 8px; cursor: pointer; background: white; box-sizing: border-box; text-align: center;
                a{display: block;}
                img{width: 100%; height: auto; display: block;}
                span{font-weight: bold; text-align: center; margin: 0}
            }
        }
    }
}

.dashboard-container > h3, .classroom-container > h3 {
    margin-top: 2em;
    font-weight: bold;
}

@media screen and (max-width: 639px){
    .dashboard-container{
        &.student{}
        &.teacher{
            .classroom-container{}
            ul{
                li{width: 100%; margin: 0 0 0.2em 0;
                    a{}
                    img{}
                    span{}
                }
            }
        }
    }
}