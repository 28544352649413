#footer{ display: flex; padding: 2em 0; text-align: center; background-color: #333;
    .col{width:50%;
        span{display: block; margin: 0 auto; color: white;
            &.bold{font-size: 16px; font-weight: 600;}
        }
        img{display: block; margin: 1em auto 0 auto; width: 50px; height: auto;}
    }
}

@media screen and (max-width: 639px){
    #footer{display: none;}
}