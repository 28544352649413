
.errorBanner {
    left: 0px;
    top: 60px;
    z-index: 10;
    font-weight: bold;
    position: fixed;
    line-height: 60px;
    background: #ff8100;
    margin-top: 0;
    text-align: center;
    width: 100%;
}